import React from 'react';

export class Footer extends React.Component {
    render () {
        return (
            <div className="footer-div">
                <a href="https://beian.miit.gov.cn/">晋ICP备20001113号</a>
                <br />
                
                <a href="https://beian.miit.gov.cn/">Copyright © 2020 Naonao Studio. All rights reserved. </a>
                {/* <img href="/images/beian.png"/> */}
                <img src="/images/beian.png"></img>
            </div>
        )
    }
}