// import { Space } from "antd";
import React from 'react';

export class Social extends React.Component {
    render () {
        return (
            <div className="social-div">
                <a className="social-icon" href="mailto:admin@wudinaonao.com" target="_blank" rel="noopener noreferrer"><i class="fa fa-envelope"></i></a>
                <a className="social-icon" href="https://www.github.com/wudinaonao" target="_blank" rel="noopener noreferrer"><i class="fab fa-github"></i></a>
                <a className="social-icon" href="https://www.zhihu.com/people/wen-lu-zhao" target="_blank" rel="noopener noreferrer"><i class="fab fa-zhihu"></i></a>
                <a className="social-icon" href="https://www.youtube.com/channel/UCFX7jvxEj6iH3iZwtf4FWtw?view_as=subscriber" target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube"></i></a>
                <a className="social-icon" href="https://www.instagram.com/wudinaonao250/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
            </div>
        )
    }
}