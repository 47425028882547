import React from 'react';

export class About extends React.Component {
    render () {
        return (
            <div className="about-div">
                <p>Hello, My name is Naonao, I'am a programmer and photographer.</p>
                <p>I like Java, Python, Go, etc. I live in Haikou, China. </p>
                <p>Welcome to contact me, just scan QR code or send an email!</p>
                {/* <p>大家好！我是闹闹，我喜欢Linux，软件开发，例如，Java，Python等。我还是一名摄影爱好者。</p> */}
                <div className="wechat-div">

                </div>
            </div>
        )
    }
}