import React from 'react';

export class Navbar extends React.Component {

    render () {
        return (
            <div className="navbar-div">
                <a className="link" href="https://www.wudinaonao.com" target="_blank" rel="noopener noreferrer">HOME</a>
                <a className="link" href="https://blog.wudinaonao.com" target="_blank" rel="noopener noreferrer">BLOG</a>
                <a className="link" href="https://photo.wudinaonao.com" target="_blank" rel="noopener noreferrer">PHOTO</a>
            </div>
        )
    }
}