import React from 'react';
// import '~antd/dist/antd.css';
import { About } from './about';
// import './css/style.scss';
import { Footer } from './footer';
import { Navbar } from './navbar';
import { Social } from './social';



function App() {
  return (
    <div className="App">
        {/* <h1>999</h1> */}
          <video className="background-video" autoplay="autoplay" muted loop="loop">
              <source src="/movies/A77A2977_1.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
        </video>
        <Navbar />
        <div className="description-div">
            <About />
            <Social />
        </div>
        <Footer />

    </div>
  );
}

export default App;
